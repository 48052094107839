<template>
    <popper trugger= "hover" :options= "{placement:'top'}" >
        <div class="popper popper-balls">
            <router-link :to="{ name:'OpportunitiesListCat', params:{ catId:k} }" >
                <div class="flex items-center mb-2">
                    <div class="rounded text-xs px-3 py-2px text-xs text-black"  :style="'background-color:' + legendColors[k]">{{k}}</div>
                    <div class="ml-auto text-xs">
                        {{ row.oportunity | numberFormat() }}<span class="text-font-gray"> Op.</span>
                    </div>
                </div>
                <div class="flex items-center">
                    <div class="text-font-gray text-xs mr-4">Sales</div>
                    <div class="text-aux  text-xs">{{ row.sales | reduceBigNumbers(2) }}€</div>
                </div>
            </router-link>
        </div>
        <div class="w-12 h-12 rounded-full bg-font-gray text-black flex items-center
            justify-center relative font-semibold shadow m-2 cursor-pointer" slot="reference">
            {{ row.oportunity | numberFormat() }}
            <div class="rounded py-px px-2 absolute right-0 top-0 text-xxs transform translate-x-3 -translate-y-1 shadow"
                 :style="'background-color:' + legendColors[k]">{{ k }}</div>
        </div>
    </popper >
</template>

<script>
    export default {
        name: "BallLost",
        props: ['row', 'k', 'data'],
        computed: {
            legendColors() { return this.$legendColorsBySector(this.data.sectors) }
        }
    }
</script>